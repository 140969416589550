import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export default function AnimatedLink(props) {
  return (
    <AniLink
      duration={1}
      direction="right"
      bg="rgb(51,58,65)"
      style={{ cursor: "pointer" }}
      {...props}
    >
      {props.children}
    </AniLink>
  )
}
