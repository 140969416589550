import { Link } from 'gatsby';
import { Navbar, Nav } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import logo from '../images/logo.png';
import NavItem from './navItem';
import AnimatedLink from "./AnimatedLink"

const Header = ({ pageInfo }) => (
  <Navbar expand='lg' variant='dark' fixed='top' id='site-navbar'>
    <Link to='/' className='link-no-style px-4'>
      <Navbar.Brand as='span'>
        <img src={logo} alt='Vulcan AI' style={{ width: 200 }} />
      </Navbar.Brand>
    </Link>
    <Navbar.Toggle aria-controls='basic-navbar-nav' />
    <Navbar.Collapse id='basic-navbar-nav'>
      <Nav
        className='ml-auto navbar-right'
        activeKey={pageInfo && pageInfo.name}
      >
        {/* <NavItem route="industries" display="Industry 4.0" /> */}
        <NavItem route='sense' display='Sense+' />
        <NavItem route='replant' display='Replant+' />
        <NavItem route='health' display='Health+' />
        <NavItem route='nutrient' display='Nutrient+' />
        <NavItem route='smartgrader' display='SmartGrader' />
        {/* <NavItem route='trade' display='Trade+' /> */}
        <NavItem route='careers' display='Careers' />
        <div className="d-block d-lg-none">
              <NavItem route="contact" display="Get Started" />
        </div>
        {/* <NavItem route='contact' display='Get Started' /> */}

      </Nav>
    </Navbar.Collapse>
    <div style={{ marginLeft: 20 }} className="d-none d-xl-block pr-4">
          <AnimatedLink
            to="/contact"
            className="btn bg-light text-primary text-bold m-auto"
            style={{ width: 150 }}
          >
            Get Started
          </AnimatedLink>
    </div>
  </Navbar>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
