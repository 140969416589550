import React from 'react';
import { navigate } from "gatsby"

export default function GetStarted() {
  return (
    <div className=' contact_us py-5'>
      <div className='row container mx-auto my-5'>
        <div className='contact_heading'>
          <h2>Get Started</h2>
          <div className='line my-4'></div>

          <p className='lead my-0 pb-4'>
            Get in touch to learn more about how Vulcan AI can partner with you
            for Agri & Forestry Solutions
          </p>

          <button
            className='btn'
            onClick={() => {
              navigate('/contact')
              // var win = window.open(
              //   '/contact',
              //   '_blank'
              // );
              // win.focus();
            }}
          >
            Contact Us
          </button>
        </div>
      </div>
    </div>
  );
}
